<div class="app-header" *ngIf="userService.currentUser" @fadeInTop>

	<div class="logo-wrapper">

		<div class="header-dropdown" ngbDropdown container="body" (openChange)="showSwitch = !showSwitch">
			<button class="btn" ngbDropdownToggle #dropDownButton>
				<i class="fas fa-th"></i>
			</button>

			<div ngbDropdownMenu #ngbDropDown class="dropdown-menu dropdown-menu-right" role="menu">
				<evo-switch *ngIf="switchProductsOrganizations && showSwitch"
				            (switchOrganizations)="goToOrganizationList(); dropDownButton.click();"
				            [switchProductsOrganizations]="switchProductsOrganizations"
				            [showAdmin]="showAdminBtn.value"
							[isInternal]="isInternal">
				</evo-switch>
			</div>
		</div>

		<div class="product-logo QUALITY pointer" routerLink="{{ homepage }}"></div>
		<!-- <img class="evollo-logo" src="/assets/images/evollo_logo.png"/> -->

		<h5 class="pointer" routerLink="{{ homepage }}">Eva InsightHub</h5>
	</div>

	<div class="action-wrapper">
		<!-- <div class="header-dropdown" ngbDropdown container="body">
			<button class="btn" ngbDropdownToggle>
				<i class="fas fa-circle text-success"></i>
			</button>

			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
				<app-speed-test *ngIf="organizationService.currentOrganization && organizationService.currentOrganization.id"></app-speed-test>

			</div>

		</div> -->

		<div class="action-buttons">
			<!-- <button class="btn">
				<i class="fas fa-bell"></i>
			</button>
			<button class="btn">
				<i class="fas fa-question-circle"></i>
			</button> -->

			<button class="btn" (click)="openHelpDialog()" *ngIf="organizationService.currentOrganization?.id && showSupport">
				<img src="/assets/icons/helpdesk.svg" width="18" height="18" style="opacity: 0.7;"/>
			</button>

			<a href="https://evollosupport.zendesk.com/hc/pt-br" target="_blank" class="btn" *ngIf="showFaq">
				<i class="far fa-question-circle"></i>
			</a>

			<!-- <button class="btn" (click)="goToOrganizationList()">
				<i class="fas fa-cog"></i>
			</button> -->
		</div>

		<span class="user-info">
			{{ userService.currentUser.name }}
		</span>

		<div class="header-dropdown" ngbDropdown container="body">
			<avatar-initials style="cursor: pointer;" [small]="true" [initialsColor]="'#fff'" [name]="userService.currentUser.name" ngbDropdownToggle></avatar-initials>

			<!-- <button class="btn pl-2" data-no-icon="true" ngbDropdownToggle>
				<i class="fas fa-ellipsis-h"></i>
			</button> -->
			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right py-2" role="menu">
				<a class="dropdown-item pointer" [href]="idUrl + '/profile'">
					<i class="far fa-user"></i>
					<span class="">Configuração da conta</span>
				</a>

				<hr class="my-1"/>

				<a class="dropdown-item pointer" (click)="logout()">
					<img class="mr-2" src="/assets/icons/sign-out.svg" width="18" height="18" opacity="0.8"/>
					Sair
				</a>
			</div>
		</div>
	</div>

</div>
